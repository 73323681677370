import React from 'react'
import {
  Card,
  Heading,
  Text,
  Section,
  Accordion,
  AccordionItem,
} from '@jsluna/react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import SEO from '../components/Seo'
import PrivacyPolicyContent from '../data/privacyPolicy'

const PrivacyPolicy = () => {
  return (
    <Layout testid="privacy-policy-page">
      <SEO title="Privacy Policy" />
      <Section className="page">
        <Hero />
        <Section className="page_content">
          <Card className="privacy_policy__wrapper">
            <Heading level={3}>Sainsbury’s Group Privacy Policy</Heading>
            <Text element="p">
              We understand that your privacy and the security of your personal
              information is extremely important. This notice sets out what we
              do with your personal information, what we do to keep it secure,
              from where and how we collect it, as well as your rights in
              relation to the personal information we hold about you.
            </Text>
            <Text element="p">
              This policy applies if you interact with us through the Active
              Kids holiday club scheme.
            </Text>
            <Text element="p">
              If you don’t want to read all the detail, here are the things we
              think you’d really want to know:
            </Text>
            <ul>
              <li>
                The Active Kids holiday club scheme is run by Sainsbury’s
                Supermarkets Ltd
              </li>
              <li>
                The personal information that you provide us about yourself and
                your child, is shared with partners who support us in running
                the Active Kids sessions
              </li>
              <li>
                The personal information that you provide to us, will be held in
                the U.K.
              </li>
              <li>
                You have a number of rights over your personal information. How
                you can exercise these rights is set out in this notice
              </li>
              <li>
                Sainsbury’s will contact you about the Active Kids Holiday club
                scheme, if you have given us your permission
              </li>
            </ul>
            {PrivacyPolicyContent.map(({ sectionTitle, children }) => (
              <Section key={sectionTitle}>
                <Heading level={4}>{sectionTitle}</Heading>
                <Accordion multipleOpen>
                  {children.map(({ title, content }) => (
                    <AccordionItem title={title} key={title}>
                      {content}
                    </AccordionItem>
                  ))}
                </Accordion>
              </Section>
            ))}
          </Card>
        </Section>
      </Section>
    </Layout>
  )
}

export default PrivacyPolicy
