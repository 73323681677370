import React from 'react'

const PrivacyPolicyContent = [
  {
    sectionTitle: '',
    children: [
      {
        title: 'Who are we?',
        content: (
          <div>
            <p>
              The Active Kids holiday club scheme is run by Sainsbury’s
              Supermarkets Ltd. The personal information captured as part of the
              program will be used to support us and our partners deliver the
              service.
            </p>
            <p>
              Sainsbury’s Supermarkets Ltd registered office: 33 Holborn,
              London, EC1N 2HT. For our contact address, please see the Contact
              Us section below.
            </p>
          </div>
        ),
      },
      {
        title: 'What sorts of personal information do we hold?',
        content: (
          <div>
            <ul>
              <li>
                Information that you provide to us such as your Nectar card
                number, name, address, date of birth, telephone number, email
                address, payment card details, and any feedback you give to us,
                including by phone, email, post, or when you communicate with us
                via social media;
              </li>
              <li>
                Information about Active Kids holiday club that we provide to
                you, including the activities that you have registered your
                child for;
              </li>
              <li>
                Information that you provide to us about your child, such as
                their name, date of birth, allergies that they may suffer from;
              </li>
              <li>
                Your account credentials such as your username and chosen
                password; and
              </li>
              <li>
                Information from companies who support us with the running of
                the Active Kids holiday clubs
              </li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Our legal basis for processing your personal information',
        content: (
          <div>
            <p>
              Whenever we process your personal information we have to have
              something called a “legal basis” for what we do. The different
              legal bases which we rely on are:
            </p>
            <ul>
              <li>
                <strong>Consent: </strong>
                <>
                  You have told us you are happy for us to process your personal
                  information for a specific purpose;
                </>
              </li>
              <li>
                <strong>Legitimate interests: </strong>
                <>
                  The processing is necessary for us to conduct our business,
                  but not where our interests are overridden by your interests
                  or rights;
                </>
              </li>
              <li>
                <strong>Performance of a contract: </strong>
                <>
                  We must process your personal information in order to be able
                  to provide you with one of our products or services;
                </>
              </li>
              <li>
                <strong>Prevention of fraud: </strong>
                <>
                  Where we are required to process your data in order to protect
                  us and our customers from fraud or money laundering;
                </>
              </li>
              <li>
                <strong>Vital interests: </strong>
                <>
                  The processing of your personal information is necessary to
                  protect you or someone else’s life;
                </>
              </li>
              <li>
                <strong>Public information: </strong>
                <>
                  Where we process personal information which you have already
                  made public;
                </>
              </li>
              <li>
                <strong>Legal claims: </strong>
                <>
                  The processing of your personal information is necessary for
                  the establishment, exercise or defence of legal claims or
                  whenever courts are acting in their judicial capacity; and
                </>
              </li>
              <li>
                <strong>Legal obligation: </strong>
                <>
                  We are required to process your personal information by law.
                </>
              </li>
            </ul>
          </div>
        ),
      },
      {
        title: 'How do we use your personal information?',
        content: (
          <div>
            <p>
              There are a number of ways in which we use your personal
              information, depending on how you interact with us. If you do not
              provide your information to us, then we will be unable to interact
              with you in that way – for example if you do not provide your name
              and email address we will not be able to tell you about the Active
              Kids holiday clubs that are available close to you.
            </p>
            <p>We may use your information in the following ways:</p>
            <p>
              <strong>To provide Active Kids holiday clubs </strong>
              <>
                - we use your personal information to let you know whether the
                service is available to book in your area. If you then decide to
                register your child onto the Active Kids holiday club then we’ll
                be delighted, thank you. After that, we will use the information
                you have provided to make sure the scheme runs smoothly.
              </>
            </p>
            <p>
              Where consent has been obtained from you as the parent or
              guardian, feedback will be sought from your child via a feedback
              form about the Active Kids Holiday club.
            </p>
            <p>
              <strong>For safety and security </strong>
              <>
                - some of the venues where the Active Kids holiday clubs are
                taking place, may have CCTV installed. This is to make sure your
                child is safe at all times whilst the activity takes place.
              </>
            </p>
            <p>
              <strong>Analytics </strong>
              <>
                - we use your personal information for statistical analysis and
                to help us understand more about how the Active Kids Scheme is
                performing. This includes the sessions which are booked onto,
                frequency of booking and any feedback that you or your child may
                have provided about the sessions.
              </>
            </p>
            <p>
              <strong>Contacting you </strong>
              <>
                - we may use your personal information to contact you; either to
                conduct market research to contact you about the well-being of
                your child whilst attending an Active Kids holiday club with us.
                We may also contact you in relation to any questions you have
                raised with us.
              </>
            </p>
            <p>
              If you have opted in to receive information about Active Kids and
              future events then we may use the details that you have provided
              to contact you.
            </p>
            <p>
              If you want to stop receiving these emails please contact
              <a href="mailto:activekids_support@Sainsburys.co.uk">
                activekids_support@Sainsburys.co.uk
              </a>
              <>
                {' '}
                or by clicking on the unsubscribe link at the bottom of the
                email.
              </>
            </p>
          </div>
        ),
      },
      {
        title: 'Who might we share your personal information with?',
        content: (
          <div>
            <p>
              <strong>Our service providers </strong>
              <>
                - we work with partners to help us run the Active Kids holiday
                clubs. These partners process your personal information on our
                behalf and are required to meet our standards of security before
                doing so. We only share information that allows them to provide
                their services to us or to facilitate them providing their
                services to you.
              </>
            </p>
            <p>These partners include:</p>
            <ul>
              <li>
                Scheme providers, such as Premier Education, who we partner with
                so we can schedule and run the Active Kids Program;
              </li>
              <li>
                Trainers, who run the Active Kids activities, so they are able
                to verify children that have been booked onto the courses;
              </li>
              <li>
                Market research partners, who help us understand how the Active
                Kids Scheme is performing;
              </li>
              <li>
                Companies that provide insights and analytics services, so we
                can understand how the Active Kids holiday clubs are performing;
              </li>
              <li>
                Security and fraud prevention companies to ensure the safety and
                security of our customers, colleagues and business; and
              </li>
              <li>
                Companies which run our contact centres because they need your
                personal information to identify and contact you.
              </li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Keeping you informed about our products and services',
        content: (
          <div>
            <p>
              We would like to keep you updated about Active Kid holiday clubs.
              Where we have your consent or it is in our legitimate interests to
              do so, we may do this through the post, by email, text message or
              by any other electronic means.
            </p>
            <p>
              We won’t send you marketing messages if you tell us not to, but if
              you have signed up to the scheme we may still need to send you
              information about the Active Kids Holiday club or occasional
              service-related messages.
            </p>
          </div>
        ),
      },
      {
        title: 'Your rights',
        content: (
          <div>
            <p>
              You have a number of rights under data protection legislation
              which, in certain circumstances, you may be able to exercise in
              relation to the personal information we process about you.
            </p>
            <p>These include:</p>
            <ul>
              <li>
                the right to access a copy of the personal information we hold
                about you;
              </li>
              <li>
                the right to correction of inaccurate personal information we
                hold about you;
              </li>
              <li>
                the right to restrict our use of your personal information;
              </li>
              <li>the right to be forgotten;</li>
              <li>the right of data portability; and</li>
              <li>
                the right to object to our use of your personal information.
              </li>
            </ul>
            <p>
              Where we rely on consent as the legal basis on which we process
              your personal information, you may also withdraw that consent at
              any time.
            </p>
            <p>
              If you are seeking to exercise any of these rights, please contact
              us using the details in the “Contact Us” section below. Please
              note that we will need to verify your identity before we can
              fulfil any of your rights under data protection law. This helps us
              to protect the personal information belonging to our customer
              against fraudulent requests.
            </p>
          </div>
        ),
      },
      {
        title: 'How long will we keep your personal information for?',
        content: (
          <p>
            We will keep your personal information for the purposes set out in
            this privacy policy and in accordance with the law and relevant
            regulations. We will never retain your personal information for
            longer than is necessary. In most cases, our retention period will
            come to an end 6 months after the Active Kids holiday club has
            finished for the year. However, in some instances we will be
            required to hold some personal information for 7 years in line with
            statutory limitation periods.
          </p>
        ),
      },
      {
        title: 'Security',
        content: (
          <p>
            We take protecting your personal information seriously and are
            continuously developing our security systems and processes to keep
            your information secure.
          </p>
        ),
      },
      {
        title: 'Contact us',
        content: (
          <div>
            <p>
              If you would like to exercise one of your rights as set out in the
              “Your rights”, or you have a question or a complaint about this
              policy, or the way your personal information is processed, please
              contact us by one of the following means:
            </p>
            <div>
              <>By email: </>
              <a href="mailto:privacy@sainsburys.co.uk">
                privacy@sainsburys.co.uk
              </a>
            </div>
            <div>
              By post: : Data Protection Officer at Privacy Team, Sainsbury’s
              Supermarkets Ltd, 16th Floor, Arndale House, Manchester, M4 3AL
            </div>
            <p>
              You also have the right to lodge a complaint with the UK
              regulator, the Information Commissioner. Go to
              <> </>
              <a href="https://ico.org.uk/concerns">ico.org.uk/concerns</a>
              <> to find out more.</>
            </p>
          </div>
        ),
      },
      {
        title: 'Policy change',
        content: (
          <p>
            This privacy policy was most recently updated in April 2019. If we
            make changes to it, then we will take appropriate steps to bring
            those changes to your attention.
          </p>
        ),
      },
    ],
  },
]

export default PrivacyPolicyContent
